export default
[
	{
		"inputs": [
			{
				"internalType": "contract IWrappedCoin",
				"name": "_wrappedCoin",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "MAX_FEE",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "PERCENT_FACTOR",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claimFeesETH",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "contract IUniRouterV2",
				"name": "_router",
				"type": "address"
			},
			{
				"internalType": "address[]",
				"name": "_path",
				"type": "address[]"
			}
		],
		"name": "convertFees",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "feeReceiver",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "contract IToken",
				"name": "_from",
				"type": "address"
			},
			{
				"internalType": "contract IToken",
				"name": "_to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_amountIn",
				"type": "uint256"
			},
			{
				"internalType": "contract IUniRouterV2[]",
				"name": "_routers",
				"type": "address[]"
			},
			{
				"internalType": "contract IToken[]",
				"name": "_additionalTokens",
				"type": "address[]"
			}
		],
		"name": "findSwapPathes",
		"outputs": [
			{
				"components": [
					{
						"internalType": "contract IUniRouterV2",
						"name": "router",
						"type": "address"
					},
					{
						"internalType": "address[]",
						"name": "path",
						"type": "address[]"
					},
					{
						"internalType": "uint256",
						"name": "amountOut",
						"type": "uint256"
					}
				],
				"internalType": "struct ML_SwapPathFinder.SwapPathInfo[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amountIn",
				"type": "uint256"
			},
			{
				"internalType": "contract IUniRouterV2",
				"name": "_router",
				"type": "address"
			},
			{
				"internalType": "address[]",
				"name": "_path",
				"type": "address[]"
			}
		],
		"name": "getSwapEstimate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "referralManager",
		"outputs": [
			{
				"internalType": "contract IReferralManager",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "serviceName",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_swapFee",
				"type": "uint256"
			}
		],
		"name": "setFee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			}
		],
		"name": "setFeeReceiver",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"components": [
					{
						"internalType": "contract IUniRouterV2",
						"name": "lpRouter",
						"type": "address"
					},
					{
						"internalType": "contract ITokenPair",
						"name": "pair",
						"type": "address"
					},
					{
						"internalType": "contract IUniRouterV2",
						"name": "router0",
						"type": "address"
					},
					{
						"internalType": "contract IUniRouterV2",
						"name": "router1",
						"type": "address"
					},
					{
						"internalType": "address[]",
						"name": "path0",
						"type": "address[]"
					},
					{
						"internalType": "address[]",
						"name": "path1",
						"type": "address[]"
					}
				],
				"internalType": "struct ZAP.LPSwapInfo",
				"name": "_swapInfoOut",
				"type": "tuple"
			},
			{
				"internalType": "uint256",
				"name": "_minAmountOut",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_referralID",
				"type": "uint256"
			}
		],
		"name": "swapCoinToLP",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "contract IUniRouterV2",
				"name": "_router",
				"type": "address"
			},
			{
				"internalType": "address[]",
				"name": "_path",
				"type": "address[]"
			},
			{
				"internalType": "uint256",
				"name": "_minAmountOut",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_referralID",
				"type": "uint256"
			}
		],
		"name": "swapCoinToToken",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "swapFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"components": [
					{
						"internalType": "contract IUniRouterV2",
						"name": "lpRouter",
						"type": "address"
					},
					{
						"internalType": "contract ITokenPair",
						"name": "pair",
						"type": "address"
					},
					{
						"internalType": "contract IUniRouterV2",
						"name": "router0",
						"type": "address"
					},
					{
						"internalType": "contract IUniRouterV2",
						"name": "router1",
						"type": "address"
					},
					{
						"internalType": "address[]",
						"name": "path0",
						"type": "address[]"
					},
					{
						"internalType": "address[]",
						"name": "path1",
						"type": "address[]"
					}
				],
				"internalType": "struct ZAP.LPSwapInfo",
				"name": "_swapInfoIn",
				"type": "tuple"
			},
			{
				"internalType": "uint256",
				"name": "_amountIn",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_minAmountOut",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_referralID",
				"type": "uint256"
			}
		],
		"name": "swapLPToCoin",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "swapLPToLP",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"components": [
					{
						"internalType": "contract IUniRouterV2",
						"name": "lpRouter",
						"type": "address"
					},
					{
						"internalType": "contract ITokenPair",
						"name": "pair",
						"type": "address"
					},
					{
						"internalType": "contract IUniRouterV2",
						"name": "router0",
						"type": "address"
					},
					{
						"internalType": "contract IUniRouterV2",
						"name": "router1",
						"type": "address"
					},
					{
						"internalType": "address[]",
						"name": "path0",
						"type": "address[]"
					},
					{
						"internalType": "address[]",
						"name": "path1",
						"type": "address[]"
					}
				],
				"internalType": "struct ZAP.LPSwapInfo",
				"name": "_swapInfoIn",
				"type": "tuple"
			},
			{
				"internalType": "uint256",
				"name": "_amountIn",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_minAmountOut",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_referralID",
				"type": "uint256"
			}
		],
		"name": "swapLPToToken",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "contract IUniRouterV2",
				"name": "_router",
				"type": "address"
			},
			{
				"internalType": "address[]",
				"name": "_path",
				"type": "address[]"
			},
			{
				"internalType": "uint256",
				"name": "_amountIn",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_minAmountOut",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_referralID",
				"type": "uint256"
			}
		],
		"name": "swapTokenToCoin",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amountIn",
				"type": "uint256"
			},
			{
				"components": [
					{
						"internalType": "contract IUniRouterV2",
						"name": "lpRouter",
						"type": "address"
					},
					{
						"internalType": "contract ITokenPair",
						"name": "pair",
						"type": "address"
					},
					{
						"internalType": "contract IUniRouterV2",
						"name": "router0",
						"type": "address"
					},
					{
						"internalType": "contract IUniRouterV2",
						"name": "router1",
						"type": "address"
					},
					{
						"internalType": "address[]",
						"name": "path0",
						"type": "address[]"
					},
					{
						"internalType": "address[]",
						"name": "path1",
						"type": "address[]"
					}
				],
				"internalType": "struct ZAP.LPSwapInfo",
				"name": "_swapInfoOut",
				"type": "tuple"
			},
			{
				"internalType": "uint256",
				"name": "_minAmountOut",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_referralID",
				"type": "uint256"
			}
		],
		"name": "swapTokenToLP",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "contract IUniRouterV2",
				"name": "_router",
				"type": "address"
			},
			{
				"internalType": "address[]",
				"name": "_path",
				"type": "address[]"
			},
			{
				"internalType": "uint256",
				"name": "_amountIn",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_minAmountOut",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_referralID",
				"type": "uint256"
			}
		],
		"name": "swapTokenToToken",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "transferGas",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wrappedCoin",
		"outputs": [
			{
				"internalType": "contract IWrappedCoin",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	}
]