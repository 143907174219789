import { useTheme } from "../../../hooks";

const Image = (props) =>
{
	const theme = useTheme();

	const cn =
	[
		theme?.getClassName("Image") ?? "",
		props.className
	].join(" ");

    if (!props.src
        || props.src === "")
    {
        return (
            <div
                className={cn}
                style={props.style}
                onClick={props.onClick}
            />
        );
    }
    else
    {
        return (
            <img
                className={cn}
                src={props.src}
                alt=""
                style={props.style}
                onClick={props.onClick}
            />
        );
    }
};

export default Image;