//components
import { Grid, Flex, Text } from "../../";

//styles
import styles from "./Page.module.css";

const PageHeader = (props) =>
{
    return (
        <div className={styles.pageHeader_wrapper}>
            <Grid className={styles.pageHeader}>
                <Flex>
                    <Text
						color={3}
						weight={0}
						size={2}
						lineHeight={1}
					>
                        {props.title}
                    </Text>
                </Flex>
                <Flex>
                    <Text
						color={1}
						weight={0}
						size={0}
						lineHeight={1}
					>
                        {props.children}
                    </Text>
                </Flex>
            </Grid>
        </div>
    );
};

export default PageHeader;