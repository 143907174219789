import { useState, useEffect } from "react";

//styles
import styles from "./Inputs.module.css";

const TextToggle = (props) =>
{
    //current values
    const cur_values = props.values || [];
    const cur_value = props.value;
    const cur_selectedIndex = (cur_values.indexOf(cur_value) === -1 ? 0 : cur_values.indexOf(cur_value));

    //state
    const [values, setValues] = useState(() => cur_values);
    const [value, setValue] = useState(() => cur_values[cur_selectedIndex]);
    const [selectedIndex, setSelectedIndex] = useState(() => cur_selectedIndex);

    //effects
    useEffect(() =>
    {
        const idx = (cur_values.indexOf(cur_value) === -1 ? 0 : cur_values.indexOf(cur_value));
        setValues(cur_values);
        setSelectedIndex(idx);
        setValue(cur_values[idx]);
    }, [JSON.stringify(cur_values)]);

    //handler
    const trySelectItem = (_idx) =>
    {
        setSelectedIndex(_idx);
        if (values[_idx] !== value)
        {
            //value changed            
            setValue(values[_idx]);
            if (props.onChange)
            {
                props.onChange(values[_idx], _idx);
            }
        }
    };
    const handleClickDirection = (_idx) =>
    {
        const direction = _idx - selectedIndex;
        let idx = selectedIndex;
        if (direction < 0)
        {
            idx = ((idx - 1) + values.length) % values.length;
        }
        else if (direction > 0)
        {
            idx = (idx + 1) % values.length;
        }

        trySelectItem(idx);
    };
    const handleClickItem = (_idx) =>
    {
        trySelectItem(_idx);
    };

    return (
        <div className={[styles.textToggle, props.className].join(" ")}>     
            {values.map((v) => (
                <div
                    key={`val_${values.indexOf(v)}`}
                    className=
                    {
                        [
                            styles.textToggleItem,
                            (values.indexOf(v) === selectedIndex
                                ? styles.textToggleItemEnabled
                                : styles.textToggleItemDisabled)
                        ].join(" ")
                    }
                    onClick={() => handleClickItem(values.indexOf(v))}
                >
                    {v.text}
                </div>
            ))}
        </div>
    );
};

export default TextToggle;