import { useEffect } from "react";

//framework
import { MLUtils } from "../utils";

export const useEventSubscription = (events, callback, condition = []) =>
{
    useEffect(() =>
    {
        MLUtils.subscribeEvents(events, callback);
        return () => MLUtils.unsubscribeEvents(events, callback);
    }, condition);
}