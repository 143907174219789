import configPage from "./page";
import configUI from "./ui";
import configLabels from "./labels";
import configWeb3 from "./web3";
import configDebug from "./debug";

const config =
{
	debug: configDebug,
	web3: configWeb3,
	ui: configUI,
	menu: null,
	chains: null,
	page: configPage,
	labels: configLabels
};

export default config;