//styles
import styles from "./Emblem.module.css";

export const EmblemColor =
{
    gray: styles.emblem_gray,
    white: styles.emblem_white,
    green: styles.emblem_green,
    blue: styles.emblem_blue,
    red: styles.emblem_red,
    yellow: styles.emblem_yellow,
    violet: styles.emblem_violet,
};

const Emblem = (props) =>
{
    return (
        <div
            className={[styles.emblem, props.color, props.className].join(" ")}
            style={props.style}
        >
            {props.children}
        </div>
    );
};

export default Emblem;