//styles
import styles from "./Page.module.css";

const PageContent = (props) =>
{
    return (
        <div className={styles.pageContent}>
            {props.children}
        </div>
    );
};

export default PageContent;