const configChains =
{
    defaultChain: 56,
    chains:
    [
		{
			id: 1
		},
        {
            id: 25
        },
        {
            id: 56
        },
        {
            id: 137
        },
        {
            id: 250
        },
        {
            id: 1666600000
        }
    ]
};

export default configChains;