import React, { useRef } from "react";

//framework
import { MLUtils } from "../../../utils";

//styles
import styles from "./Inputs.module.css";

const InputBNAmount = (props) =>
{
    //state
    const inputRef = useRef();

    //handler
    const handleChange = (e) =>
    {
        if (props.onChange)
        {
            props.onChange(e, inputRef.current.value);
        }
    };
    const handleBlur = (e) =>
    {
        if (props.onBlur)
        {
            props.onBlur(e, inputRef.current.value);
        }
    };

    return (
        <input
            id={props.id}
            ref={inputRef}
            name={props.name}
            className=
            {
                [
                    (MLUtils.defaultBoolean(props.rawStyle, false) ? "" : styles.input),
                    styles.inputBN,
                    props.className
                ].join(" ")
            }
            style={props.style}
            type="number"
            placeholder={props.placeholder}
            value={props.value ?? ""}
            decimals={props.decimals}
            min={0}
            max={props.max}
            step={props.step}
            readOnly={props.readOnly}
            onChange={(e) => handleChange(e)}
            onBlur={(_e) => handleBlur(_e)}
        />
    );
};

export default InputBNAmount;