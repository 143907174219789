const configDebug =
{
	dApp:
	{
		moduleProvider: false,
		moduleLoaded: false
	},
	oracle:
	{
		showPrices: false
	}
};

export default configDebug;