//components
import
{
    Text,
    Flex,
    FormatPercent
} from "@MoonLabsDev/dapp-core-lib";

//styles
import styles from "./MoonReferral.module.css";

const MoonReferralFeeInfo = ({fee}) =>
{
    return (
        <Flex className={styles.feeInfo}>
            <Text size={0}>
                {fee.service}
            </Text>
            <Text size={0}>
                {fee.userFee !== -1 &&
                    <>
                        <FormatPercent
                            value={fee.userFee}
                            smart={true}
                        />
                        {" "}/{" "}
                    </>
                }
                <FormatPercent
                    value={fee.maxFee}
                    smart={true}
                />
            </Text>
        </Flex>
    );
};

export default MoonReferralFeeInfo;