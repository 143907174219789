const configMenu =
{
	toolComponent: null,
    desktopMode:
    {
        header:
        {
            menu: "normal",
			tool: "none",
            user: "normal"
        },
        footer:
        {
            menu: "none",
			tool: "none",
            user: "none"
        }
    },
    tabletMode:
    {
        header:
        {
            menu: "minimal",
			tool: "none",
            user: "normal"
        },
        footer:
        {
            menu: "none",
			tool: "none",
            user: "none"
        }
    },
    mobileMode:
    {
        header:
        {
            menu: "none",
			tool: "none",
            user: "app"
        },
        footer:
        {
            menu: "app",
			tool: "none",
            user: "none"
        }
    },

    desktopMenu:
    [
        {
            text: "Home",
            link: "/"
        },
        {
            text: "Test",
            link: "/tests/general",
            children:
            [
                {
                    text: "General",
                    link: "/tests/general"
                },
                {
                    text: "Cards",
                    link: "/tests/cards"
                },
                {
                    text: "Charts",
                    link: "/tests/charts"
                },
                {
                    text: "Modals",
                    link: "/tests/modals"
                }
            ]
        }
    ],

    tabletMenu: undefined, //will use desktop instead

    mobileMenu:
    [
        {
            text: "General",
            link: "/tests/general"
        },
        {
            text: "Cards",
            link: "/tests/cards"
        },
        {
            text: "Charts",
            link: "/tests/charts"
        },
        {
            text: "Modals",
            link: "/tests/modals"
        }
    ]
};

export default configMenu;