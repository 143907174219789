import { NavLink as RouterLink } from "react-router-dom";

const NavLink = ({className, style, children, href, onClick}) =>
{
	return (
		<RouterLink
			className={className}
			style={style}
			to={href}
			onClick={onClick}
		>
			{children}
		</RouterLink>
	);
};

export default NavLink;