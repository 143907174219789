export const Chart_defaultColors =
[
    "rgb(76, 175, 80)", //green
    "rgb(244, 67, 54)", //red
    "rgb(63, 81, 181)", //blue
    "rgb(255, 193, 7)", //yellow
    "rgb(205, 220, 57)", //lime
    "rgb(103, 58, 183)", //purple
    "rgb(3, 169, 244)", //light blue
    "rgb(255, 152, 0)", //orange
    "rgb(96, 125, 139)", //grey
    "rgb(121, 85, 72)" //brown
];